import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: No encontrado" />
    <h1>404: recurso no encontrado</h1>
    <p>Si buscabas nuestros planes de futuro aquí no los encontrarás.</p>
  </Layout>
)

export default NotFoundPage
